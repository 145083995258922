import { useStore } from '@/store'
import { usePlayerStore } from '@/store/player';
const store = useStore();
const playerStore = usePlayerStore();


function updateTracklist() {
	let { uri, progress } = getTrack(store.tunecast.start_time);
	let track = store.playlistTracks.find(t => t.spotify_id === uri.split(':')[2]);
	playerStore.currentTrack = {...track, progress};
}

let updateInterval;

export async function pauseAudio() {
	clearInterval(updateInterval);

	switch (store.DSP) {
		case 'APPLE': {
			await window.apple_player.pause();
			break;
		}
		case 'SPOTIFY': {
			window.spotify_player.activateElement();
			await window.spotify_player.pause();
			break;
		}
	}
}

function getRemainingApple(startTime) {
	let lapsedTime = Date.now() - startTime;
	let position = store.tunecast.loop
	? lapsedTime -
	Math.floor(lapsedTime / store.tunecast.duration) * store.tunecast.duration
	: lapsedTime;

	let track = { isrc: "", marker: null };

	store.tracks.forEach((t, index) => {
		if (t.marker > position) return;

		if (!track.marker) {
			return (track = {
				index,
				isrc: t.isrc,
				marker: t.marker,
				apple_id: t.apple_id,
			});
		}

		// if it's the closest to the clock
		if (t.marker > track.marker) {
			return (track = {
				index,
				isrc: t.isrc,
				marker: t.marker,
				apple_id: t.apple_id,
			});
		}
	});

	let remaining_isrcs = store.tracks.filter((t) => t.marker > position);

	// prepend current track isrc
	if (track.isrc) remaining_isrcs.unshift(track);
	// convert all isrcs to apple ids
	return {
		remaining_isrcs: remaining_isrcs.map((t) => t.apple_id),
		progress: position - track.marker,
	};
}

function getTrack(startTime) {
	let lapsedTime = Date.now() - startTime;
	let position = store.tunecast.loop
	? lapsedTime - Math.floor(lapsedTime / store.tunecast.duration) * store.tunecast.duration
	: lapsedTime;

	let track = { uri: "", marker: null };

	store.tracks.forEach((t, idx) => {
		if (t.marker > position) return;

		// if the there is no base track
		if (!track.marker)
			return (track = {
				index: idx,
				uri: `spotify:track:${t.spotify_id}`,
				marker: t.marker,
			});

		if (t.marker > track.marker)
			return (track = {
				index: idx,
				uri: `spotify:track:${t.spotify_id}`,
				marker: t.marker,
			});
	});

	return { ...track, progress: position - track.marker };
}

let tryingToPlay = false;
export async function playAudio(force) {
	console.log('i am being invoked...')
	if(!updateInterval) setInterval(updateTracklist, 1000);
	if (store.state !== 2) return;
	let track;

	if (tryingToPlay === true) return;
	tryingToPlay = true;
	setTimeout(() => (tryingToPlay = false), force ? 500 : 2500);

	let data;

	console.log('[DEBUG] playing track..');

	switch (store.DSP) {
		case 'APPLE': {
			track = getTrack(store.tunecast.start_time, store.DSP);
			data = getRemainingApple(store.tunecast.start_time);

			let playing;

			/**
			* closure variable to make sure listener handle doesnt run twice
			*/
			let triggered = false;

			/**
			* WAIT FOR STATE TO CHANGE, BEFORE SYNCING :D
			* Otherwise you get an @sourceBufferError
			*/
			window.apple_player.addEventListener("playbackStateDidChange", async (e) => {
				console.log('state changed', e);
				if (e.state !== 2 || triggered) return;

				triggered = true;
				try {
					await window.apple_player.seekToTime(data.progress / 1000);
				} catch (err) {
					return console.log("ERROR SEEKING", err);
				}

				// window.apple_player.volume = 1;
				// this.tryingToPlay = false;
			});

			console.log('about to play apple***')
			console.log(data,data.remaining_isrcs)

			try {
				await window.apple_player.setQueue({
					songs: data.remaining_isrcs.map(i => ''+i), // queue's last first song
					// startPosition: data.progress
				});
			} catch (err) {
				return console.log("ERROR QUEUING", err);
			}

			// window.apple_player.volume = .01;

			try {
				playing = await window.apple_player.play();
				console.log('[DEBUG]', playing)
			} catch (err) {
				return console.log("ERROR PLAYING APPLE", err);
			}

			break;
		}
		case 'SPOTIFY': {
			if(!window.spotify_player || !window.spotifyReady) return setTimeout(playAudio, 500);
			window.spotify_player.activateElement();
			console.log('playing spotify track');
			let keys = store.tracks;
			track = getTrack(store.tunecast.start_time, store.DSP);
			console.log('the track is..', track)
			play({
				playerInstance: window.spotify_player,
				spotify_uri: track.uri,

				uris: keys.map((k) => `spotify:track:${k.spotify_id}`),
				position_ms: track.progress,
			});

			tryingToPlay = false;
			break;
		}
		// case this.EXP.SPOTIFYMOB:
		// 	let res = await fetch(
		// 	`${window.env.api}/resync?ns=${this.props.ns}&accessToken=${this.props.token}`
		// 	);
		// 	data = await res.json();

		// 	if (data.err)
		// 		return this.openModal();

		// 	this.closeModal();

		// 	if (data.ended) return console.log("IT ENDED");

		// break;
	}
}


export const initializeSpotify = token => {
	initSpotifyWebPlayback(
		token,
		_onSpotifyPlayerReady,
		() => {}, // state change
		() => {} // blocked
	);

	window.addScript("https://sdk.scdn.co/spotify-player.js");
}


function _onSpotifyPlayerReady(player) {
	window.spotifyReady = true;
	window.spotify_player = player;
}

export const checkSavedTracks = async (token,trackIds) => {
	const response = await fetch(`https://api.spotify.com/v1/me/tracks/contains?ids=${trackIds.join(',')}`, {
		headers: {
			'Authorization': 'Bearer ' + token
		}
	});

	let json = await response.json();
	return json;
}

export const saveTrack = async (token,trackId) => {
	await fetch(`https://api.spotify.com/v1/me/tracks?ids=${trackId}`, {
		method: 'PUT',
		headers: {
			'Authorization': 'Bearer ' + token
		}
	})
}

export const play = async ({
	uris,
	spotify_uri,
	position_ms,
	playerInstance: {
		_options: { getOAuthToken, id },
	},
}) => {
	const token = await getOAuthToken((access_token) => {
		return access_token;
	});

	await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${id}`, {
		method: 'PUT',
		headers: { 
			Authorization: `Bearer ${token}` 
		},
		body: JSON.stringify({
			uris,
			offset: {
				uri: spotify_uri,
			},
			position_ms,
		})
	});
};

function initSpotifyWebPlayback(
	oauth_token,
	onPlayerReady,
	onPlayerStateChange,
	onSpotifyPlaybackBlocked
) {
	window.onSpotifyWebPlaybackSDKReady = () => {
		console.log('initializing!', oauth_token)
		const token = oauth_token;

		let token_update = Date.now();

		let spotify_player = new window.Spotify.Player({
			name: "tunecast.com",
			getOAuthToken: async (cb) => {
				if (Date.now() - token_update < 1000 * 60 * 60) return cb(token);

				// fetch token
				token_update = Date.now();
				cb(token);
			},
		});

		// Error handling
		spotify_player.addListener("initialization_error", ({ message }) => {
			console.error("init error", message);
		});
		spotify_player.addListener("authentication_error", ({ message }) => {
			console.error("auth error", message);
		});
		spotify_player.addListener("account_error", ({ message }) => {
			console.error("account error", message);
		});
		spotify_player.addListener("playback_error", (data) => {
			console.error("There was an error with spotify playback", data);
			onSpotifyPlaybackBlocked("Playback blocked by Spotify Error");
		});

		spotify_player.addListener("player_state_changed", onPlayerStateChange);

		spotify_player.addListener("autoplay_failed", () => {
			onSpotifyPlaybackBlocked("Autoplay blocked by mobile browser");
		});

		// Ready
		spotify_player.addListener("ready", ({ device_id }) => {
			spotify_player._options.id = device_id;
			onPlayerReady(spotify_player);
		});

		// Not Ready
		spotify_player.addListener("not_ready", ({ device_id }) => {
			console.log("Device ID has gone offline", device_id);
		});

		// Connect to the player!
		spotify_player
		.connect()
		.then(() => {
			window.spotify_connected = true; 
			console.log("successfully connected to spotify connect!")
		});
	};
}